.movie {
  &--overlay {
    position: fixed;
    top: 0;
    left: 100%;
    height: 100vh;
    width: 100vw;
    background-color: rgba(black, 1);
    z-index: 222;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--closeBtn {
    position: absolute;
    top: 2em;
    right: 2em;
    width: 70px;
    cursor: pointer;
    z-index: 230;

    &:hover {
      .movie--closeBtn__inner::before,
      .movie--closeBtn__inner::after {
        transform: rotate(0);
      }

      .movie--closeBtn__inner::before {
        top: 0;
      }

      .movie--closeBtn__inner::after {
        bottom: 0;
      }

      label {
        opacity: 1;
      }
    }

    &__inner {
      width: inherit;
      text-align: center;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: inherit;
        background-color: white;
        left: 0;
        transition: all 0.3s ease-in;
      }

      &::before {
        top: 50%;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 50%;
        transform: rotate(-45deg);
      }
    }

    label {
      font-size: 0.8em;
      line-height: 4;
      text-transform: uppercase;
      transition: all 0.3s ease-in;
      opacity: 0;
      cursor: pointer;
    }
  }

  &--iframeContainer {
    position: relative;
    height: 100%;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      z-index: 223;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .movie {
    &--overlay {
      padding: 3em;
    }
  }
}
