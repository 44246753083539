.form {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 555;
  transform: translateX(100%);
  // transition: 0.3s ease-in;

  &.active {
    // transition: 0.3s ease-out;
    // transform: translateX(0);
  }

  &--container {
    padding: 1em 2em;
  }

  &--title {
    font-family: 'Righteous', sans-serif;
    color: black;
    font-size: 4em;
    height: 2.5em;
    overflow: hidden;
    white-space: pre-line;
    line-height: 0.8;

    .split-parent {
      overflow: hidden;
      // border: solid red thin;
    }
  }

  // &--title {
  //   border: solid red thin;
  //   overflow: hidden;

  //   &__word {
  //     font-family: 'Righteous', sans-serif;
  //     color: black;
  //     font-size: 4em;
  //     line-height: 0.8;
  //   }
  // }

  &--inputGroup {
    color: black;
    display: flex;
    flex-direction: column;
    margin-top: 1.5em;

    label {
      font-size: 0.9em;
      font-weight: 700;
    }

    textarea,
    input {
      padding: 0.5em 1em;
      // border: solid transparent;
      font-family: 'Space Mono', monospace;

      &.error {
        transition: 0.3s;
        border: solid red;
      }
    }
  }

  &--submit {
    display: block;
    margin-top: 2em;
    margin-left: auto;
    padding: 0.5rem 1rem;
    width: 5em;
    font-family: 'Righteous', sans-serif;
    font-size: 1.5em;
    background: none;
    color: green;
    border: solid 2px green;
    outline: none;
    cursor: pointer;
    transition: 0.25s;

    &:hover,
    &:focus {
      box-shadow: inset 0 -3.25em 0 0 green;
      color: white;
    }
  }

  &--closeBtn {
    position: absolute;
    top: 2em;
    right: 2em;
    width: 70px;
    cursor: pointer;
    z-index: 230;

    &:hover {
      .form--closeBtn__inner::before,
      .form--closeBtn__inner::after {
        transform: rotate(0);
      }

      .form--closeBtn__inner::before {
        top: 0;
      }

      .form--closeBtn__inner::after {
        bottom: 0;
      }

      label {
        opacity: 1;
      }
    }

    &__inner {
      width: inherit;
      text-align: center;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: inherit;
        background-color: black;
        left: 0;
        transition: all 0.3s ease-in;
      }

      &::before {
        top: 50%;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 50%;
        transform: rotate(-45deg);
      }
    }

    label {
      font-size: 0.8em;
      line-height: 4;
      text-transform: uppercase;
      transition: all 0.3s ease-in;
      opacity: 0;
      color: black;
      cursor: pointer;
    }
  }

  &--error {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    padding: 1em;
    background-color: $rojoBack;
    color: white;
    text-align: center;
  }
}

.thanks {
  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(black, 0.3);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 577;
  }

  &--container {
    padding: 1em;
    background-color: rgba(green, 0.8);
  }
}

@media screen and (max-height: 500px) {
  .form {
    &--title {
      font-size: 2.9em;
      height: 2em;
    }
  }
}

@media screen and (max-height: 600px) {
  .form {
    &--title {
      font-size: 3.2em;
      height: 2.5em;
    }

    &--closeBtn {
      width: 50px;
    }

    &--submit {
      margin-top: 1em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .form {
    width: 30em;

    &--container {
      padding: 2em;
    }
  }
}
