// Colors
$rojoBack: #f83d0c;

// Sizes24
$bp-xsmall: 20.625;
$bp-small: 24.15; // 386.4
$bp-medium: 48; // 768
$bp-large: 60.25; // 964
$bp-xlarge: 75; // 1200

:root {
  // **************
  // CONTAINERS
  // **************
  --container-padding: 0 2rem;
  --container-maxwidth: 1440px;
}
