.servicios {
  scroll-snap-align: none;
  height: auto;
  margin-top: 10vh;

  // section {
  //   height: auto;
  // }

  &--item {
    position: relative;
    width: 20em;
    margin-bottom: 5em;

    &:nth-of-type(odd) {
      margin-left: auto;
    }

    &:nth-of-type(even) {
      .servicios--item__playBtn {
        right: 0;
        left: auto;
        transform: translate(20%, -20%);
      }
    }

    &__textContainer {
      // background-color: black;
      background-color: rgba(black, 0.8);
      padding: 1.5rem;
      padding-bottom: 4em;
      margin: 0;
    }

    &__title {
      font-family: 'Righteous', sans-serif;
      font-size: 2.5em;
      // font-size: 4em;
      // word-wrap: break-word;
      // line-height: 0.75;
      line-height: 0.9;
    }

    &__description {
      font-family: 'Space Mono', monospace;
      margin-top: 3em;
      font-weight: 300;
    }

    &__imgContainer {
      position: relative;
      height: 30vh;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(rgb(166, 142, 3), 0.3);
      }

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &__playBtn {
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: white;
      padding: 0.5em;
      transform: translate(-20%, -20%);

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .servicios {
    .container {
      padding: 0 3em;
    }

    &--item {
      &_2 {
        // transform: translateY(-50%);
        margin-top: -50%;
      }
      &_3 {
        margin-top: -30%;

        // transform: translateY(-75%);
      }
      &_4 {
        margin-top: -30%;
        // transform: translateY(-100%);
      }
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .servicios {
    .container {
      padding: 0 4em;
    }
  }
}

@media screen and (min-width: 68em) {
  .servicios {
    &--item {
      display: flex;
      width: 90%;
      height: 20em;
      // transform: translateY(0);
      margin-top: 5em;

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }

      &__imgContainer {
        width: 38%;
        height: 100%;
      }

      &__textContainer {
        padding: 1.5em;
        width: 62%;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // gap: 3em;
      }

      &__title {
        // width: 4em;
        // margin: 0;
        font-size: 4em;
      }

      &__description {
        // margin: 0;
      }
    }
  }
}
