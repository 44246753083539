.nosotros {
  scroll-snap-align: none;

  // padding: 4em 0;
  .container {
    display: flex;
    align-items: center;
    // border: solid yellow;
  }

  &--gridContainer {
    // border: solid pink;
    width: 100%;
  }

  &--title {
    background-color: white;
    color: black;
    font-family: 'Righteous', sans-serif;

    font-size: 3.5em;
    // font-size: 2.5em;
    // font-size: 4em;
    text-align: right;
    width: fit-content;
    margin-left: auto;
    // padding: 0.5em 1em;
    padding: 0.5rem 1rem;

    // font-weight: 700;
    font-weight: 600;
    text-align: center;

    span {
      font-size: 1.1em;
    }
  }

  &--demo {
    .iframeContainer {
      background-color: black;
    }
  }

  &--description {
    // font-weight: 700;
    font-family: 'Space Mono', monospace;
    font-weight: 400;
    background-color: rgba(black, 0.8);
    padding: 2em;
    width: 62%;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .nosotros {
    .container {
      padding: 0 3em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .nosotros {
    .container {
      padding: 0 4em;
      display: flex;
      align-items: center;
    }

    &--gridContainer {
      display: grid;
      grid-template-columns: min-content auto;
      grid-template-rows: min-content min-content;
      height: fit-content;
      width: 100%;
      // border: solid blue;
    }

    &--title {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      font-size: 3em;
      margin-left: 0;
      width: auto;
    }

    &--demo {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }

    &--description {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
      width: auto;
      margin-top: 3em;
      margin-right: 3em;
      font-size: 1.25em;
    }
  }
}
