.hero {
  scroll-snap-align: none;
  position: relative;

  &--titleContainer {
    width: fit-content;
    margin: 0 auto;
  }

  &--title {
    color: #ffffff;
    font-family: 'Righteous', sans-serif;
    font-size: 7em;
    line-height: 1;
    margin: 0.25em auto 0 auto;
    letter-spacing: 5px;
  }

  &--subtitle {
    letter-spacing: 1px;
  }

  &--button {
    position: absolute;
    top: calc(45vh + 5em);
    right: 0;
    padding: 0.25em;
    font-size: 1.25em;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    background-color: green;
    cursor: pointer;
    z-index: 500;
    color: green;
    user-select: none;
  }

  .nah {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);
    font-family: 'Righteous', sans-serif;
    color: white;
    font-size: 4em;
    text-align: center;
    user-select: none;
  }
}

@media screen and (max-height: 500px) {
  .hero {
    &--title {
      font-size: 6em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .hero {
    &--titleContainer {
      margin: 0;
      // border: solid yellow;
    }

    &--title {
      font-size: 8em;
      margin-top: 0.5em;
      margin-left: 0.5em;
      text-align: left;

      #mexa {
        margin-left: 1.5em;
      }
    }

    &--subtitle {
      text-align: right;
    }

    &--button {
      top: calc(45vh + 4em);
    }

    .nah {
      font-size: 6em;
    }
  }
}
