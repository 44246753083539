.cursor {
  display: none;
  position: fixed;
  border-radius: 50%;
  pointer-events: none;
  z-index: 666;
  width: 80px;
  height: 80px;
  background-color: white;
  mix-blend-mode: exclusion;
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .cursor {
    display: block;
  }
}
