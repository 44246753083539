.clients {
  height: auto;
  background-color: rgba(black, 0.8);
  margin-top: 20vh;
  margin-bottom: 20vh;

  .container {
    position: relative;
    padding: 2em 0;
    // border: solid yellow;
  }

  &--title {
    position: absolute;
    top: -1.5em;
    left: 1em;
  }

  ul {
    display: flex;
    align-items: center;
  }

  li {
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      max-height: 8em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .clients {
    &--title {
      left: 3em;
    }
  }
}
@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .clients {
    &--title {
      left: 4em;
    }
  }
}
