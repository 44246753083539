html {
  // scroll-behavior: smooth;
  // scroll-snap-type: y mandatory;
}

body {
  background-color: $rojoBack;
  font-family: "Space Mono", monospace;
  color: white;
  // scroll-padding-top: 50px;
}

.glsl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
}

.container {
  max-width: 90em;
  height: 100%;
  margin: 0 auto;
}

section {
  height: 100vh;
}

@media screen and (max-width: calc(#{$bp-xsmall}*1em)) {
  body {
    font-size: 14px;
  }
}

@media screen and (min-width: calc(#{$bp-small}*1em)) {
  section {
    // border: solid blue;
  }
}
@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  section {
    // border: solid orange;
  }
}
@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .container {
    // padding: 0 2em;
  }

  section {
    // border: solid green;
  }
}
@media screen and (min-width: calc(#{$bp-xlarge}*1em)) {
  section {
    // border: solid black;
  }
}
