.footer {
  background-color: rgba(black, 0.8);

  .container {
    padding: 2em 1em;
  }

  &--social {
    display: flex;
    margin-bottom: 1.5em;

    &__item {
      display: inline-block;
      height: 1.5em;
      width: 1.5em;
      margin-right: 2em;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &--copy {
    font-size: 0.8em;
  }
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .footer {
    .container {
      padding: 1em 3em;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--social {
      margin-bottom: 0;
    }

    &--copy {
      font-size: 1em;
    }
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .footer {
    .container {
      padding: 1em 4em;
    }
  }
}
