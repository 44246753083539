// .contact {
//   position: fixed;
//   top: 50%;
//   left: 0;
//   height: 60px;
//   width: 60px;
//   font-weight: 700;
//   letter-spacing: 2px;
//   border: solid black 2px;
//   border-top-right-radius: 25px;
//   border-bottom-right-radius: 25px;
//   z-index: 660;
//   background-color: white;

//   svg {
//     height: 100%;
//     width: 100%;
//   }
// }

.contact {
  position: fixed;
  top: 45vh;
  right: 0;
  // height: 90px;
  // width: 45px;

  padding: 0.25em;
  text-align: center;
  background-color: white;
  // text-orientation: sideways;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: black;
  z-index: 500;
  font-size: 1.25em;
  cursor: pointer;
}

@media screen and (min-width: calc(#{$bp-medium}*1em)) {
  .contact {
    // top: 2em;
    // right: 3em;
  }
}

@media screen and (min-width: calc(#{$bp-large}*1em)) {
  .contact {
    // top: 2em;
    // right: 4em;
  }
}
